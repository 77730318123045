.course-tab {
    position: inherit;
    margin-top: 30px;
    padding: 40px;
    border-radius: 10px;
    padding-top: 40px;
    background: var(--color-blackest);
    padding-bottom: 50px;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    transition: 0.4s;
    cursor: pointer;
    overflow: hidden;
    display: inline-block;

    @media #{$lg-layout} {
        padding: 30px 20px;
    }
    @media #{$md-layout} {
        padding: 30px 20px;
    }
    @media #{$sm-layout} {
        padding: 30px 20px;
    }
    
    .icon {
        position: relative;
        border-radius: 100%;
        font-size: 40px;
        display: inline-block;
        margin-bottom: 17px;
        line-height: 6px;
        transition: all .4s ease;
        z-index: 2;
    }
    .icon-color1{
        color: var(--color-primary);
    }
    .icon-color2{
        color: #FB5343;
    }
    .icon-color3{
        color: #6549D5;
    }

    .inner {
        position: relative;
        z-index: 2;
        .flex{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px;
        }
        .gap--25{
            gap: 10px 25px;
        }
        .outline{
            position: relative;
        }
        .outline::after{
            position: absolute;
            content: '';
            left: 0;
            bottom: -10px;
            width: 120px;
            height: 3px;
            background-color: var(--color-primary);
        }
        h4 {
            &.title {
                font-size: 22px;
                font-weight: 700;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            br {
                
                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }
            a {
                color: var(--color-body);
                @extend %transition;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
        .course-label{
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            padding: 5px 12px;
            background: var(--color-primary);
            color: var(--color-white);
            font-size: 1.8rem;
            transition: all .4s ease;
            border: 1px solid var(--color-primary);
            &:hover{
                background: var(--color-blackest);
            }
            svg{
                font-size: 2.2rem;
            }
        }
        .course-detail{
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 3px 0px; 
            font-size: 1.7rem;
            color: var(--color-body);
            &:hover{
                color: var(--color-primary)
            }
            svg{
                font-size: 2.2rem;
            }
        }
    }
    &:hover {
        transform: translateY(-5px)
    }
    // &:hover p a,
    // &:hover .course-label{
    //     color: white !important;
    // }
}