
.gallery-slider { 
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 0;
	overflow: hidden;
}

.row-gallery{
	position: relative;
	white-space: nowrap;
	will-change: transform;
}
.row-gallery .img-container {
	position: relative;
	width: 100%;
	max-width: 450px;
	height: 540px;
	display: inline-block; 
	margin: 0px 14.5px;
	@media #{$large-mobile} {
		max-width: 410px;
		height: 500px;
	}
}
.img-container .img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	transition: all 0.4s ease-in-out;
	overflow: hidden;
}
.img-container:hover .img {
	cursor: pointer;
	transform: translateY(-20px);
}

.img-container .img-logo {
	position: absolute;
	z-index: 5;
	bottom: -70px;
	left: 50%;
	transform: translateX(-50%);
	width: 100px !important;
	height: 100px;
	border-radius: 50%;
	padding: 6px;
	background-color: var(--color-white);
	transition: all .6s ease;
	opacity: 0;
}
.img-container:hover .img-logo {
	bottom: -30px;
	opacity: 1;

}


.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}