.custom-cursor {
	position: fixed;
	width: 30px;
	height: 30px;
	-webkit-border-radius: 50%; 
	-moz-border-radius: 50%; 
	border-radius: 50%; 

	display: block;
	pointer-events: none;
	mix-blend-mode: multiply;
	transform: scale(1);
	background-color: rgb(122 122 122 / 50%);
	transition: transform .35s ease-out, ;
	z-index: 10000;

	@media #{$sm-layout} {
		display: none;
	}
}
  
.custom-cursor.hovering {
    transform: translate(-50%, -50%) scale(2.5);
}