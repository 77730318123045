/*------------------------
    Banner Styles  
-------------------------*/
// Slider Dot 
.slider-dot {
    &.rn-slick-dot {
        .slick-dots {
            bottom: 60px;
        }
    }
}

.height-850 {
    height: 850px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.height-650 {
    height: 650px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}


.height-750 {
    height: 750px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.height-950 {
    height: 950px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.height-1020 {
    height: 1020px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.display-one {
    font-size: 70px;
    strong{
        color: var(--color-primary);
    }
    @media #{$lg-layout} {
        font-size: 65px;
    }
    @media #{$md-layout} {
        font-size: 58px;
    }
    @media #{$sm-layout} {
        font-size: 50px;
    }
    @media #{$large-mobile} {
        font-size: 42px;
    }
    @media #{$small-mobile} {
        font-size: 35px;
    }
}

.display-two {
    font-size: 85px;
    @media #{$lg-layout} {
        font-size: 70px;
    }
    @media #{$md-layout} {
        font-size: 65px;
    }

    @media #{$sm-layout} {
        font-size: 65px;
    }

    @media #{$large-mobile} {
        font-size: 46px;
    }
}



.bg-overlay {
    position: relative;

    &::before {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 1;
        top: 0;
        left: 0;
        background-color: transparent;
        background-image: linear-gradient(90deg, #060606 0%, #06060600 90%);
        content: "";
    }
}



.bg-overlay-solid {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        height: 100%;
        width: 40%;
        opacity: 1;
        top: 0;
        left: 0;
        content: "";
        background: var(--color-dark);
        z-index: -1;
    }

    &::after {
        position: absolute;
        width: 500px;
        height: 500px;
        border-radius: 1000px;
        opacity: 0.5;
        filter: blur(100px);
        left: auto;
        top: -250px;
        left: -250px;
        bottom: auto;
        background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
        content: "";
        z-index: -1;
    }
}


.frame-image {
    padding: 20px;
    position: relative;
    right: 0;
    border-radius: 10px;
    transition: var(--transition);
    height: 100%;
    background-color: var(--color-blackest);
    animation: anime1 1.5s ease-in-out infinite alternate;
    @media #{$sm-layout} {
        display: none;
    }
    img {
        width: 100%;
    }

    &:hover {
        transform: translateY(-5px);
    }
}

.slider-area {
    .inner {
        a,
        button {
            &.btn-default {
                &.btn-border {
                    border-color: var(--color-border);
                    color: var(--color-white);
                }
            }
        }
    }
}

/*-----------------------
    Slider Style One  
-------------------------*/

.slider-style-1 {
    .inner {
        .subtitle {
            font-family: var(--font-primary);
            font-size: 18px;
            letter-spacing: 0.5px;
            display: block;
            margin-bottom: 18px;
            color: var(--color-body);

            @media #{$md-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$sm-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$large-mobile} {
                font-size: 14px;
            }
        }

        .title {
            font-family: var(--font-primary);
            font-weight: 900;
            margin-bottom: 25px;
            display: inline-block;
            
            &.strock{
                color: transparent;
                -webkit-text-stroke: 0.6px white;
                background: linear-gradient(90deg, white, var(--color-primary));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
            }

            @media #{$sm-layout} {
                -webkit-text-stroke: 0px white;
            }
            span {
                display: inline-block;

                label {
                    margin-bottom: 0;
                }
            }
        }

        .description {
            font-family: var(--font-primary);
            padding: 0 22%;
            font-size: 22px;
            line-height: 1.7;
            margin-bottom: 0px;
            color: var(--color-body);
            strong{
                color: var(--color-primary);
            }
            @media #{$lg-layout} {
                padding: 0 14%;
                font-size: 22px;
            }

            @media #{$md-layout} {
                padding: 0 1%;
                font-size: 20px;
            }

            @media #{$sm-layout} {
                padding: 0 1%;
                font-size: 20px;
            }

            @media #{$large-mobile} {
                font-size: 18px;
            }
        }

        &.text-start {
            .description {
                padding: 0;
            }
        }


    }

    &.variation-default {
        .inner {
            padding-top: 80px;
        }
    }

    &.variation-2 {
        position: relative;
        .thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }

}

// Slider Style Two 

.slider-style-2 {
    padding-top: 130px;

    @media #{$md-layout} {
        padding-top: 150px;
    }

    @media #{$sm-layout} {
        padding-top: 150px;
    }
    .inner {
        .subtitle {
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 18px;
            font-size: 14px;
            border-radius: 4px;
            margin-bottom: 12px;
            display: inline-block;
            padding: 7px 14px;
            background-color: var(--color-blackest);
            box-shadow: var(--shadow-lighter);
        }

        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            color: var(--color-white);
        }

        .list-icon {
            li {
                color: var(--color-body);
            }
        }
    }

    &.variation-2 {
        padding-top: 79px;
        @media #{$md-layout} {
            padding-top: 170px;
        }
        @media #{$sm-layout} {
            padding-top: 170px;
        }

        .inner {
            .title {
                font-weight: 500;
                font-size: 75px;
                margin-top: 0;
                @media #{$md-layout} {
                    font-size: 54px;
                }
                @media #{$sm-layout} {
                    font-size: 54px;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                }
            }

            .tag-title {
                color: var(--color-secondary);
                margin: 0;
                letter-spacing: 1.5px;
                padding-left: 129px;
                position: relative;
                margin-top: 50px;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    width: 100px;
                    height: 2px;
                    background: var(--color-secondary);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &.variation-3 {
        padding-top: 80px;
        @media #{$md-layout} {
            padding-top: 180px;
        }

        @media #{$sm-layout} {
            padding-top: 180px;
        }

        .inner {
            .title {
                font-family: var(--font-primary);
                margin-bottom: 25px;
                display: block;
                font-size: 80px;
                @media #{$md-layout} {
                    font-size: 60px;
                }
                @media #{$sm-layout} {
                    font-size: 50px;
                    br {
                        display: none;
                    }
                }
            }

            .description {
                font-size: 20px;
                line-height: 37px;
                margin-bottom: 0;
                @media #{$sm-layout} {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }
}

// Slider Style Three 

.slider-style-3 {
    @media #{$md-layout} {
        padding: 100px 0;
    }

    @media #{$sm-layout} {
        padding: 100px 0;
    }

    .inner {
        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 130px;
            line-height: 1.1;

            @media #{$lg-layout} {
                font-size: 100px;
            }

            @media #{$md-layout} {
                font-size: 80px;
            }

            @media #{$sm-layout} {
                margin-bottom: 20px;
                font-size: 50px;
            }
        }

        .description {
            font-size: 22px;
            line-height: 43px;

            @media #{$md-layout} {
                font-size: 20px;
                line-height: 38px;
            }

            @media #{$sm-layout} {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    .thumbnail {
        position: relative;
        z-index: 2;

        &::after {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: calc(100% - 130px);
            content: "";
            left: 50%;
            bottom: 0;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            background: var(--color-blackest);
            box-shadow: var(--shadow-1);
            border-radius: 6px;
        }
    }
}


// Slider Style 4 
.slider-style-4 {
    .inner {
        padding-top: 80px;

        @media #{$sm-layout} {
            padding-top: 50px;
            padding-left: 10px;
            padding-right: 10px;;
        }
        >* {
            animation-name: fadeOutUp;
            animation-fill-mode: both;
            animation-duration: 1s;
        }
        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 75px;
            line-height: 1.1;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: var(--color-white);

            @media #{$laptop-device} {
                font-size: 65px;
                
            }
            @media #{$lg-layout} {
                font-size: 58px;
            }

            @media #{$md-layout} {
                font-size: 52px;
                line-height: 1.2;
            }

            @media #{$sm-layout} {
                font-size: 50px;
                line-height: 1.28;
            }

            @media #{$large-mobile} {
                font-size: 40px;
            }
            @media #{$small-mobile} {
                font-size: 35px;
            }
        }

        .description {
            font-size: 18px;
            line-height: 38px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: var(--color-body);
            @media #{$sm-layout} {
                font-size: 18px;
                line-height: 35px;
            }
            @media #{$large-mobile} {
                font-size: 17px;
                line-height: 32px;
            }
        }

        .button-group {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
    &.variation-2 {
        .inner {
            .title {
                font-size: 70px;
                @media #{$sm-layout} {
                    font-size: 42px;
                }
            }
        }
        .bg-overlay {
            &::before {
                background: #000000;
                content: "";
                opacity: 0.5;
                background-image: none;
            }
        }
    }
    &.variation-3 {
        .inner {
            .title {
                font-size: 70px;
            }
        }
        .bg-overlay {
            &::before {
                background: #000000;
                content: "";
                opacity: 0.5;
                background-image: none;
            }
        }
    }
}

// Slider Style 5
.slider-style-5 {
    .inner {
        .subtitle {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.5px;
            position: relative;
            padding-right: 20px;
            display: inline-block;
            color: var(--color-white);

            &::after {
                position: absolute;
                content: "";
                width: 120px;
                height: 2px;
                background: var(--color-pink);
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 64px;
            line-height: 1.4;
            color: var(--color-white);

            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
    }
}



// Slider Style 5
.slider-style-6 {
    // background-color: var(--color-blackest);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 1;
        top: 0;
        left: 0;
        background-color: transparent;
        background-image: linear-gradient(90deg, #060606 0%, rgba(6, 6, 6, 0) 90%);
        content: "";
        z-index: -1;
    }
    @media #{$md-layout} {
        overflow: hidden;
        padding: 100px 0;
        padding-top: 170px;
    }
    @media #{$sm-layout} {
        min-height: 700px !important;
        
    }

    &.shape-right {
        position: relative;
        z-index: 2;
        overflow: hidden;
        .shape-image {
            position: absolute;
            right: -10px;
            top: 0;
            opacity: 0.5;
            width: auto;
            height: 100%;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
            img{
                height: 100% ;
            }
        }
    }

    &.shape-left {
        position: relative;
        .inner {
            position: relative;
            z-index: 2;
        }
        .shape-image {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.5;
            transform: rotate(180deg);
            height: 100%;
            bottom: 0;
            width: auto;
            @media #{$md-layout} {
                display: none;
            }

            @media #{$sm-layout} {
                display: none;
            }
        }
    }

    &.inheader-not-transparent {
        @media #{$md-layout} {
            padding-top: 100px;
        }
    }

    .inner {
        padding: 0 10px;
        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 53px;
            line-height: 1.1;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
                font-size: 48px;
                line-height: 1.2;
            }
            @media #{$sm-layout} {
                font-size: 42px;
                line-height: 1.28;
            }
            @media #{$large-mobile} {
                font-size: 35px;
            }
            br {
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }

        .description {
            font-size: 22px;
            line-height: 43px;

            @media #{$md-layout} {
                font-size: 20px;
                line-height: 38px;
            }

            @media #{$sm-layout} {
                font-size: 18px;
                line-height: 30px;
            }
            @media #{$large-mobile}{
                font-size: 17px;
            }
        }

        a {
            &.btn-default {
                &.btn-border {
                    border-color: var(--color-border);
                    color: var(--color-white);
                }
            }
        }
    }
    .contact-service-box{
        position: relative;
        padding: 30px 30px 0px 30px;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0px 5px 16px 0px rgba(8, 15.000000000000044, 52, 0.06);

        @media #{$sm-layout} {
            width: 95%;
            margin: 0 auto;
        }
        .discount-label{
            position: absolute;
            right: -2px;
            top: -2px;
            pointer-events: none;
            user-select: none;
            width: 130px;
        }
        .subtitle{
            position: relative;
            font-size: 1.8rem;
            font-weight: 600;
            color: #000000;
            margin-bottom: 10px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after{
                position: absolute;
                content: '';
                bottom: -8px;
                border-radius: 10px;
                height: 3px;
                width: 100px;
                background-color: var(--color-primary);
            }
        }
        h3{
            text-align: center;
            color: var(--color-blackest);
            strong{color:var(--color-primary);}
            line-height: 1.3;
        }
        input, textarea{
            color: #000000;
            box-shadow: 0px 5px 16px 0px rgba(8, 15.000000000000044, 52, 0.08);
            // background-color: #d8d8d8;
        }
        label{
            color: #000000;
        }
    }
}

.slider-perosonal-portfolio {
    &.height-750 {
        @media #{$md-layout} {
            padding-top: 180px;
        }
        @media #{$sm-layout} {
            padding-top: 180px;
        }
    }
    .inner {
        margin-top: -100px;
        @media #{$md-layout} {
            margin-top: 0;
        }
        @media #{$sm-layout} {
            margin-top: 0;
        }
        .title {
            font-size: 100px;
            color: var(--color-white);
            @media #{$md-layout} {
                font-size: 58px;
            }
            @media #{$sm-layout} {
                font-size: 46px;
            }
        }

        a,
        button {
            &.btn-default {
                &.btn-border {
                    border-color: var(--color-border);
                    color: var(--color-white);
                }
            }
        }
    }
}

// Slider Style 7
.slider-style-7 {
    .inner {
        .title {
            font-size: 100px;
            @media #{$lg-layout} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                br {
                    display: none;
                }
                font-size: 32px;
            }
        }
        
    }
}




// Slider Style 8

.slider-style-8 {
    justify-content: center;
    .inner {
        .title {
            font-size: 100px;
            line-height: 1.1;
            @media #{$md-layout} {
                font-size: 60px;
            }
            @media #{$sm-layout} {
                font-size: 46px;
            }
        }
        .description {
            font-size: 22px;
            line-height: 43px;
            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 38px;
            }
            br {
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
    }
}
// Slider Animation 
.slider-style-4 {
    .slick-active {
        .inner {
            >* {
                animation-name: fadeInUp;
            }

            .title {
                animation-delay: 0.5s;
            }

            .description {
                animation-delay: 1s;
            }

            .button-group {
                animation-delay: 1.2s;
            }
        }
    }
}



// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     .theme-gradient {
//         color:#f9004d;
//         background: none !important;
//     }
// }

@keyframes borderAnimation {
    // 0% { -webkit-text-stroke: 2px #ff6ec4; }
    // 50% { -webkit-text-stroke: 2px #b10dff; }
    // 100% { -webkit-text-stroke: 2px #ff6ec4; }
}

