.loop-slider .inner {
    pointer-events: none;
    display: flex;
    width: fit-content;
    gap: 30px;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
    }
  
@keyframes loop {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}