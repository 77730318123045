.tag {
  width: max-content;
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  color: #e2e8f0;
  font-size: 2rem;
  background: var(--color-lessdark);
  border-radius: 0.5rem;
  padding: 1rem 2.5rem;
  margin-right: 1rem;
  box-shadow: 
    0 0.1rem 0.2rem rgb(0 0 0 / 20%),
    0 0.1rem 0.5rem rgb(0 0 0 / 30%),
    0 0.2rem 1.5rem rgb(0 0 0 / 40%);
  pointer-events: none;
  @media #{$large-mobile} {
    font-size: 1.8rem;
    padding: 0.8rem 2.2rem;
  }
}
.tag span {
  pointer-events: none;
  font-size: 2rem;
  color: var(--color-primary);
}