.section-title {
    @media #{$sm-layout} {
        padding: 0 10px;
    }
    .title{
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
    }
    .subtitle {
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 25px;
        font-size: 16px;
        border-radius: 4px;
        margin-bottom: 12px;
        display: inline-block;
        padding: 7px 14px;
        background-color: var(--color-blackest);
        box-shadow: var(--shadow-lighter);
        &.radius-rounded {
            border-radius: 100px;
        }
        @media #{$large-mobile} {
            padding: 12px 14px;
        }
    }
    .description {
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

    p {
        &:empty {
            display: none;
        }
    }
}


.section-title-2 {
    .title {
        color: var(--color-body);
        display: block;
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 19px;
        opacity: 0.6;
    }
}

.section-title-3 {
    .title {
        width: 100% ;
        max-width: 980px ;
        margin: 0 auto;
        color: var(--color-white);
        display: block;
        border-bottom: 1px solid var(--color-border);
        padding: 19px 0;
        opacity: 0.8;
    }
}
.title{
    strong{
        color: var(--color-primary);
    }
}