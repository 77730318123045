/*------------------------------
    Popup Menu Styles  
--------------------------------*/
.popup-mobile-menu {
    z-index: 9999;
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s ease-out;
    .inner {
        width: 100%;
        max-width: 550px;
        z-index: 999;
        position: absolute;
        background-color: var(--color-blackest);
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
        opacity: 0;
        left: -150px;
        transition: all .5s ease-out;

        .header-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px 30px 20px;
            .logo {
                a {
                    img {
                        width: auto;
                        max-height: 75px;
                        @media #{$sm-layout} {
                            max-height: 68px;
                        }
                    }
                }
            }
            .close-menu {
                .close-button {
                    background: var(--color-darker);
                    border: 0 none;
                    color: var(--color-white);
                    width: 40px;
                    height: 40px;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    transition: all .4s ease;
                    cursor: pointer;

                    svg {
                        position: relative;
                        z-index: 2;
                        stroke-width: 2px;
                        stroke: #fff;
                        transition: all .4s ease;
                    }
                    &:hover{
                        background: var(--color-primary);
                    }
                }
            }
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
        .inner {
            opacity: 1;
            left: 0;
            overflow-y: auto;
        }
    }

    .mainmenu {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 15px 0px 15px 55px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        li {
            margin: 0;
            a {
                padding: 8px 0;
                display: block;
                font-size: 16px;
                font-weight: 500;
            }
        }
        .resMenuItem{
            opacity: 100%;
            margin-top: 0px;
            .MenuBul{
                position: relative;
                left: 0;
                line-height: 1;
                width: fit-content;
                font-size: 3.5rem;
                font-weight: 600;
                text-transform: capitalize;
                color: var(--color-white);
                transition: all cubic-bezier(0.4, 0, 0.2, 1) .4s;
                span{
                    position: absolute;
                    top:0;
                    left: -20px;
                    display: block;
                    width: max-content;
                    font-size: 1.2rem;
                    color: var(--color-body);
                    opacity: 100%;
                    transition: all cubic-bezier(0.4, 0, 0.2, 1) .4s;
                }
                &:hover{
                    left: 20px;
                    color: var(--color-primary);
                }
                &:hover span{
                    left: 0px;
                    opacity: 0%;
                }
                @media #{$md-layout} {
                    font-size: 3rem 
                }
                @media #{$sm-layout} {
                    font-size: 2.5rem 
                }
                
            }
            @media #{$md-layout} {
                .MenuBul{
                    font-size: 3rem 
                }  
            }
        }

        .has-droupdown {
            .submenu {
                height: 0;
                transition: all 0.3s;
                opacity: 0;
                visibility: hidden;
                padding: 0;
                max-width: 100%;
                list-style: none;
                padding-left: 14px;
                li {
                    a {
                        font-size: 15px;
                    }
                }
                &.active {
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                    padding-bottom: 12px;
                }
            }
        }

        .rn-megamenu {
            height: 0;
            transition: all 0.3s;
            opacity: 0;
            visibility: hidden;
            padding: 0;
            max-width: 100%;
            list-style: none;
            padding-left: 14px;
            &.active {
                height: 100%;
                opacity: 1;
                visibility: visible;
                padding-bottom: 12px;
            }
        }

        .mega-menu-item {
            list-style: none;
            padding: 0;
            margin: 0;
            padding-left: 0;
            li {
                a {
                    font-size: 15px;
                }
                &:last-child {
                    border-bottom: 1px solid var(--color-border);
                }
            }
        }

        .single-mega-item {
            &:last-child {
                .mega-menu-item {
                    li {
                        &:last-child {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .socials{   
        position: relative;
        bottom: 20px;
        padding: 20px 40px;
        transition: all .4s ease;

        .social-default{
            justify-content: flex-start;
        }
    }
}


