.animate-box {
    position: relative;
    width: 100%;
    max-width: 1950px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
}
.tag-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
}
.fade1 {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; 
}