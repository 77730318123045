/*==========================
    Contact Address 
============================*/

.contact-form-1 {
    .form-group {
        input {
            height: 70px;
            padding: 30px 20px 10px;
        }
        textarea {
            padding-top: 20px;
            padding-left: 20px;
        }
    }
}

.contact-address-list {
    list-style: none;
    padding: 0;
    margin: 0
}

/*-------------------
    Address Area  
--------------------*/
.rn-address {
    position: inherit;
    display: block;
    margin-top: 30px;
    padding: 40px;
    border-radius: 10px;
    padding-top: 40px;
    background: var(--color-blackest);
    padding-bottom: 50px;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    transition: 0.4s;
    cursor: pointer;
    overflow: hidden;

    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: var(--color-primary);
        transition: all .4s ease;
    }
    &:hover::before{
        height: 100%;
    }

    @media #{$lg-layout} {
        padding: 30px 20px;
    }
    @media #{$md-layout} {
        padding: 30px 20px;
    }
    @media #{$sm-layout} {
        padding: 30px 20px;
    }
    
    .icon {
        position: relative;
        border-radius: 100%;
        font-size: 40px;
        display: inline-block;
        margin-bottom: 17px;
        line-height: 6px;
        transition: all .4s ease;
        z-index: 2;
    }
    .icon-color1{
        color: var(--color-primary);
    }
    .icon-color2{
        color: #FB5343;
    }
    .icon-color3{
        color: #6549D5;
    }
    &:hover .icon{
        color: var(--color-white);
    }
    .inner {
        position: relative;
        z-index: 2;
        h4 {
            &.title {
                font-size: 22px;
                font-weight: 700;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            br {
                
                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }
            a {
                color: var(--color-body);
                @extend %transition;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    &:hover {
        transform: translateY(-5px)
    }
    &:hover p,
    &:hover p a{
        color: white !important;
        
    }
}


.google-map-style-1 {
    width: 100%;
    height: 550px;
    border-radius: 10px;
    .google-map {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.success-message {
    color: green;
    margin-top: 20px;
    font-size: 16px;
}


