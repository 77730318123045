/*--------------------
    Service Styles  
----------------------*/
.service {
    &.service__style--1,
    &.service__style--3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // min-height: 420px;
        padding: 50px 35px;
        padding-bottom: 55px;
        transition: all 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        position: relative;
        overflow: hidden;
        z-index: 2;

        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-primary);
            transition: all .8s ease;
            opacity: 0;
        }
        &:hover::before{
            opacity: 1;
        }

        @media  #{$lg-layout} {
            min-height: 370px;
        }
        @media #{$laptop-device} {
            min-height: 400px;
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            min-height: 320px;
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            min-height: 330px;
            padding: 30px 14px;
        }

        .icon {
            position: relative;
            @extend %transition;
            font-size: 65px;
            font-weight: 400;
            margin-bottom: 25px;
            color: var(--color-primary);
            text-align: center;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            
            @media #{$md-layout} {
                font-size: 55px;
                margin-bottom: 11px;
            }
            @media #{$sm-layout} {
                font-size: 45px;
            }
            svg {
                stroke-width: 1 !important;
            }
        }
        .title {
            font-size: 25px;
            font-weight: 500 !important;
            margin-bottom: 20px;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 8px;
            }
            @media #{$sm-layout} {
                font-size: 23px;
            }
        }
        .content {
            position: relative;
            z-index: 10;
            p {
                @extend %transition;
                ol{
                    margin: 0 !important;
                    li{
                        margin-top: 0 !important;
                    }
                }
            }
        }

        &:hover{
            background: var(--color-primary);
            // transform: translateY(-5px);
            .color-gray{
                color: white !important;
            }
            .icon{
                color: var(--color-white);
            }
        }
        &.rbt-border {
            border: 1px solid var(--color-border);
        }

        &.text-start {
            padding: 50px;
            @media #{$laptop-device} {
                padding: 40px 30px;
            }
            @media #{$lg-layout} {
                padding: 40px 30px;
            }
            @media #{$md-layout} {
                padding: 40px 30px;
            }
            @media #{$sm-layout} {
                padding: 30px;
            }
          
            .icon {
                justify-content: left;
            }
        }

        &.icon-circle-style {
            background-color: transparent;
            box-shadow: none;
            padding: 0px 35px;
            margin-top: 25px;

            @media #{$md-layout} {
                padding: 0px 10px;
            }

            @media #{$sm-layout} {
                padding: 0px 10px;
            }

            .icon {
                font-size: 39px;
                color: var(--color-primary);
                display: flex;
                justify-content: center;
                width: 90px;
                height: 90px;
                margin: 0 auto;
                margin-bottom: 23px;
                background-color: var(--color-primary-darker);
                line-height: 90px;
                align-items: center;
            }
        }

        &.with-working-process {
            position: relative;
            @media #{$lg-layout} {
                padding: 20px 0;
            }
            .icon {
                width: 180px;
                height: 180px;
                line-height: 180px;
                font-size: 60px;
                position: relative;
            }
            .line {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: 100%;
                left: 100%;
                background-color: var(--color-border);
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
            &:hover {
                transform: none;
            }
        }
    }


    &.gallery-style {
        padding: 40px 30px;
        background: var(--color-blackest);
        border-radius: 24px;
        box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
        margin-top: 30px;
        .content {
            .image {
                img {
                    border-radius: 5px;
                }
            }
            .title {
                margin-bottom: 19px;
                font-weight: 600;
                font-size: 20px;
                @extend %transition;
                margin-bottom: 10px;
                a {
                    color: inherit;
                    @extend %transition;
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
            p {
                @extend %transition;
                color: var(--color-body);
                margin-bottom: 20px;
            }
        }
    }


    &.service__style--2 {
        padding: 50px 35px;
        padding-bottom: 55px;
        transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        position: relative;
        z-index: 2;
        border-radius: 10px;
        margin-top: 30px;

        @media #{$laptop-device} {
            padding: 10px 28px;
        }
        @media #{$lg-layout} {
            padding: 10px 15px;
        }
        @media #{$md-layout} {
            padding: 10px 25px;
        }
        @media #{$sm-layout} {
            padding: 10px 18px 20px;
        }
        .image {
            margin-bottom: 23px;
            img {
                position: relative;
                width: auto;
                max-height: 220px;
                animation: anime1 1.2s infinite alternate;
            }
        }
        .content {
            .title {
                font-size: 24px;
                line-height: 1.25;
                @extend %transition;

                @media #{$sm-layout} {
                    font-size: 22px;
                }
                a {
                    color: inherit;
                    @extend %transition;
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
            p {
                @extend %transition;
                padding: 0 5%;
            }
        }
        &:hover{
            transform: translateY(-5px);
            box-shadow: 0 0 39px rgba(255, 101, 80, 0.1);
        }
    }

    &.service__style--3{
        position: relative;
        overflow: hidden;

        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: var(--color-primary);
            transition: all .8s ease;
        }
        &:hover::before{
            height: 100%;
        }

        .content{
            position: relative;
            z-index: 10;
            transition: all .8s ease;
            .description{
                transition: all .8s ease;
            }
        }
    } 
}





.service-wrapper {
    &>[class*=col] {

        &:nth-child(1)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        animation: anime1 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }

        &:nth-child(2)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-secondary);
                        animation: anime2 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }

        &:nth-child(3)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-tertiary);
                        animation: anime1 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }

        &:nth-child(4)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-pink);
                        animation: anime1 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }
        &:nth-child(5)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-light-green);
                        animation: anime2 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }
        &:nth-child(6)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-warning);
                        animation: anime1 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }
        &:nth-child(7)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-secondary);
                        animation: anime1 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }
        &:nth-child(8)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-tertiary);
                        animation: anime2 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }
        &:nth-child(9)  {
            .service {
                &.service__style--1,
                &.service__style--3 {
                    .icon {
                        color: var(--color-primary);
                        animation: anime1 1.2s infinite alternate;
                    }
                    &:hover .icon{
                        color: var(--color-white);
                    }
                }
            }
        }
    }

    &>[class*=col] {
        &:nth-child(2)  {
            .service {
                &.service__style--1 {
                    &.icon-circle-style {
                        .icon {
                            background-color: var(--color-secondary-darker);
                        }
                    }
                    
                }
            }
        }

        &:nth-child(3)  {
            .service {
                &.service__style--1 {
                    &.icon-circle-style {
                        .icon {
                            background-color: var(--color-tertiary-darker);
                        }
                    }
                    
                }
            }
        }

        &:nth-child(4)  {
            .service {
                &.service__style--1 {
                    &.icon-circle-style {
                        .icon {
                            background-color: var(--color-pink-darker);
                        }
                    }
                    
                }
            }
        }

        &:last-child {
            .with-working-process {
                
                .line {
                    display: none;
                }
            }
        }



    }
}

@keyframes anime1 {
    to{
        top: -15px;
    }
    from {
        top: 0px
    }
}
@keyframes anime2 {
    to{
        transform: scale(0.91);
        transform-origin: center;
    }
    from {
        transform: scale(1);
        transform-origin: center;

    }
}
@media #{$sm-layout} {
    @keyframes anime1 {
        to{
            top: -10px;
        }
        from {
            top: 4px
        }
    }
}