/*--------------------------
    Header Styles  
 --------------------------*/
.logo-dark {
    display: none;
}

.header-default {
    max-width: 100%;
    background-color: var(--color-darker);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    padding: 12px 4%;
    position: relative;

    .logo {
        a {
            display: flex;
            align-items: center;
            img {
                max-height: 78px;
                height: auto;
                @media #{$laptop-device} {
                    max-height: 72px;
                }
                @media #{$lg-layout} {
                    max-height: 66px;
                }
                @media #{$md-layout} {
                    max-height: 60px;
                }
                @media #{$sm-layout} {
                    max-height: 56px;
                }
            }
        }
    }

    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
    }

    &.header-relative {
        position: relative;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
        .header-btn {
            margin-left: 18px;
            @media #{$lg-layout} {
                display: none;
            }
            @media #{$md-layout} {
                margin-left: 0;
            }
            @media #{$sm-layout} {
                margin-left: 0;
                display: none;
            }
        }
    }

    a,
    button {
        &.btn-default {
            font-size: 15px;
            padding: 3px 22px;
        }
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background: transparent !important;
        backdrop-filter: inherit;
    }
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media #{$md-layout} {
        position: relative;
    }

    @media #{$sm-layout} {
        position: relative;
    }

    .header-top-bar {
        background-color: transparent;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }

    .header-default {
        background-color: transparent;
        background-image: none;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }
}



.hamberger-button {
    background: var(--color-white);
    border: 0 none;
    color: var(--color-blackest);
    width: 46px;
    height: 46px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 2px solid #ffffff;
    -webkit-box-shadow: 0px 6px 38px -9px rgba(7, 5, 28, 0.84);
    -moz-box-shadow: 0px 6px 38px -9px rgba(7, 5, 28, 0.84);
    box-shadow: 0px 6px 38px -9px rgba(7, 5, 28, 0.84);
    cursor: pointer;
    svg {
        position: relative;
        z-index: 2;
        stroke-width: 2px;
        stroke: var(--color-blackest);
    }
}

.header-left-align {
    .mainmenu-nav {
        margin-left: 30px;
    }
}



.rn-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-dark) !important;
    width: 100%;
    height: 110px;
    animation: headerSticky .95s ease forwards;
    z-index: 9;
    .logo {
        a {
            // img {
            //     max-height: 85px;
            //     @media #{$laptop-device} {
            //         max-height: 75px;
            //     }
            //     @media #{$lg-layout} {
            //         max-height: 70px;
            //     }
            //     @media #{$md-layout} {
            //         max-height: 70px;
            //     }
            //     @media #{$sm-layout} {
            //         max-height: 60px;
            //     }
            // }
            img {
                max-height: 75px;
                @media #{$md-layout} {
                    max-height: 70px;
                }
                @media #{$sm-layout} {
                    max-height: 60px;
                }
            }
        }
    }
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}