/*---------------------------
    Newsletter Styles  
----------------------------*/
.border-top-bottom {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
}

.newsletter-area {
    .border-top-bottom {
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
    }
    .border-top-only {
        border-top: 1px solid var(--color-border);
    }
}

.border-top-only {
    border-top: 1px solid var(--color-border) !important;
}


// Newsletter Style One 
.rn-newsletter-default {
    .newsletter-wrapper {
        padding: 40px 0px;
        
        @media #{$sm-layout} {
            padding: 40px 8px;
        }
    }
    .newsletter-section-title {
        .title {
            color: var(--color-primary);
            margin-bottom: 7px;
        }
        .description {
            color: var(--color-body);
            margin-bottom: 0;
        }
    }
    .rn-newsletter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 -10px;

        @media #{$large-mobile} {
           display: block;
        }

        @media #{$md-layout} {
          justify-content: flex-start;
        }

        @media #{$sm-layout} {
          justify-content: flex-start;
        }

        .form-group {
            margin-bottom: 0;
            margin: 0 10px;
            input {
                height: 55px;
                padding: 0 24px;
                min-width: 500px;
                background: var(--color-blackest);
                border: 1px solid transparent;

                @media #{$lg-layout} {
                    min-width: 400px;
                }
                @media #{$md-layout} {
                    min-width: 400px;
                }
                @media #{$sm-layout} {
                    min-width: 350px;
                }
                @media #{$large-mobile} {
                    min-width: 100%;
                    margin-bottom: 20px;
                }
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            .btn-default {
                height: 55px;
            }
        }
    }
}
.subscription-message{
    text-align: right;
    @media #{$md-layout} {
        text-align: left;
    }
    @media #{$sm-layout} {
        text-align: left;
    }
}
.subscription-message.success {
    color: green;
}

.subscription-message.error {
    color: red;
}