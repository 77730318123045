/*--------------------------
    Copyright Area  
---------------------------*/
.rn-back-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 46px;
    border-radius: 50%;
    text-align: center;
    z-index: 999 !important;
    background-color: var(--color-blackest);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.192);
    svg {
        color: #fff;
        width: 27px;
        height: 27px;   
        font-size: 14px !important;
    }
    @media #{$sm-layout} {
        display: none !important;
    }
}

// Copyright Style One 
.copyright-style-one {
    background: var(--color-darkest);
    padding: 20px 0;
    @media #{$sm-layout} {
        padding: 20px 15px;
    }
    .copyright-text {
        margin-bottom: 0;
        font-size: 16px;
        @media #{$sm-layout} {
            margin-top: 10px;
        }
        a{
            color: var(--color-primary);
        }
    }
    .ft-menu {
        padding: 0;
        margin: 0 -10px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media #{$sm-layout} {
            justify-content: center;
        }
        li {
            margin: 0;
            padding: 0 10px;
            position: relative;
            & + li {
                &::after {
                    position: absolute;
                    content: "";
                    height: 14px;
                    width: 1px;
                    background: var(--color-border);
                    left: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            a {
                font-size: 16px;
            }
        }
    }

    &.variation-two{
        background: var(--color-blackest);
    }

    &.no-border {
        border-top: 0;
    }
}
.whatsapp{
    position: fixed;
    bottom: 60px;
    right: 40px;
    animation: moveUpDown 1s ease alternate infinite;
    z-index: 100;
    img{
        width: 80px;
        @media #{$md-layout} {
            width: 70px;
        }
        @media #{$sm-layout} {
            width: 60px;
        }
    }
}

@keyframes moveUpDown {
    0%{
        bottom: 80px;
    }
    100%{
        bottom: 65px;
    }
}