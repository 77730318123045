.cd-design-section{
    padding: 150px 10px;
}

.design-portfolio{
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    .column {
        flex: 23.5%;
        max-width: 23.5%;

        @media #{$md-layout} {
            flex: 48%;
            max-width: 48%;
        }

        @media #{$sm-layout} {
            flex: 100%;
            max-width: 100%;
        }

        img{
            margin-top: 8px;
            vertical-align: middle;
            width: 100%;
        }
    }
    
    
}