.subcontainer{
    .res-cookie{
        @media #{$sm-layout} {
            padding: 15px !important;
            background: rgba(0, 0, 0, 1) !important;
        }
    }
}
.cookie-para{
    margin: 0;
    padding-right: 60px;

    @media #{$sm-layout} {
        padding: 0;
        text-align: center;
        font-size: 14px;
    }
    @media #{$small-mobile} {
        text-align: center;
    }
    button{
        position: relative;
        color: var(--color-primary);
        
        &::after{
            position: absolute;
            content: "";
            left: auto;
            bottom: -4px;
            background: currentColor;
            width: 0;
            height: 2px;
            transition: 0.3s;
            right: 0;
        }
        &:hover::after{
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}
.cookie-button{
    width: 100% ;
    max-width: 150px;
    display: inline-block;
    margin-left: 15px;
    padding: 15px 25px;
    font-weight: 600;

    // border: 1px solid #4CAF50;
    border: 1px solid var(--color-white);
    // background-color: #4CAF50;
    background-color: black;
    color: var(--color-white);
    border-radius: 30px;
    transition: all .3s ease;
    cursor: pointer;

    &:hover{
        color: black;
        border: 1px solid #fff;
        background-color: white;
    }
    @media #{$sm-layout} {
        font-size: 14px;
    }
    @media #{$large-mobile} {
        width: 100% ;
        max-width: 500px;
        text-align: center;
    }
}
.res-cookie{
    @media #{$large-mobile} {
        flex-direction: column;
        gap: 25px;
    }
}