.form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-container {
    .row div{
        margin: 10px 0;
    }
}

.notice-board, .terms{
    h6{
        position: relative;
        color: red;
        font-weight: 600;
    }
    li{
        color: var(--color-body);
        font-size: 1.5rem;
    }
}
.terms h6::after{
    position: absolute;
    content: '';
    top: 35px;
    left: 0; width: 100%;
    height: 2px;
    background: #fff;
}
.terms ul{
    margin-top: 30px;
}

.user-info{

    .nav-user-info{
        .head{
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background: #EC5C96;
        }
        .note{ 
            padding: 20px 30px;
            color: white;
            background: #059DFF;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
    .form-group{
        .gender{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

} 

.status-message {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}

.status-message.success {
    color: green;
}

.status-message.error {
    color: red;
}