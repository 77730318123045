/*--------------------------
    split Area Styles  
----------------------------*/

.rn-splite-style {
    .thumbnail {
        img {
            border-radius: 0;
            width: 100%;
        }
        &.image-left-content {
            img {
                position: relative;
                animation: mymove 3s;
                animation-direction: alternate;
                animation-timing-function: linear;
                animation-iteration-count: infinite;

                border-radius: 0 10px 10px 0;
                @media #{$lg-layout} {
                    border-radius: 0;
                }
                @media #{$md-layout} {
                    border-radius: 0;
                }
                @media #{$sm-layout} {
                    border-radius: 0;
                }
            }
            .border-left{
                border-radius: 10px 0 0px 10px;
            }
        }
    }
    .split-inner {
        padding: 30px 115px;

        @media #{$lg-layout} {
            padding: 40px 80px;
        }
        @media #{$md-layout} {
            padding: 40px 30px;
        }
        @media #{$sm-layout} {
            padding: 40px 15px;
        }
        
        .title {
            font-size: 45px;
            line-height: 50px;
            color: var(--color-heading);
            font-weight: var(--p-bold);
            @media #{$sm-layout} {
                font-size: 26px;
                line-height: 35px;
            }
            strong{
                color: var(--color-primary);
            }
        }
        p{
            margin-bottom: 10px;    
            strong{
                color: var(--color-primary);
            }
        }
        ul li{
            font-size: 16px;
            strong{
                color: var(--color-primary);
            }
        }
        .sub-title{
            font-size: 24px;
            color: var(--color-heading);
            font-weight: 500;
            @media #{$sm-layout} {
                font-size: 19px;
            }
            @media #{$large-mobile} {
                font-size: 18px;
            }
            strong{
                color: var(--color-primary);
            }
        }
        .split-list {
            list-style: none;
            padding-left: 0;
            margin: 0;
            li {
                display: flex;
                gap: 15px;
                .icon {
                    background: var(--color-primary);
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    color: var(--color-white);
                    text-align: center;
                    min-width: 30px;
                }
                @media #{$large-mobile} {
                    font-size: 16px;
                }
            }
        }
    }
}

.no-radius .rn-splite-style .thumbnail.image-left-content img {
    border-radius: 0;
}

@keyframes mymove {
    from{
        top: -20px;
    }
    to{
        top: 20px;
    }
}