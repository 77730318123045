/*-------------------------
    Darkmode Styles  
---------------------------*/
.rn-back-top svg {
    transition: 0.3s;
}
.rn-back-top:hover svg {
    color: var(--color-primary) !important;
}

// Dark Style 
body {
    &.light {
        background-color: var(--color-white);
        color: #65676b;

        .dark-icon {
            display: block;
        }

        .light-icon {
            display: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        .social-default li a,
        .rbt-single-widget .title,
        .rbt-single-widget.widget_recent_entries .inner a,
        .tagcloud a, 
        .wp-block-tag-cloud a ,
        .rwt-portfolio-details .content-left .single-list-wrapper .single-list label,
        .blog-details-content .category-meta span.text,
        .demo-slider-area.slider-style-1 .inner .title,
        .rn-back-top svg ,
        .popup-mobile-menu .inner .header-top .close-menu .close-button,
        .footer-style-default .footer-top .rn-footer-widget .title,
        .rn-call-to-action.style-8 .title,
        .rn-newsletter-default .newsletter-section-title .title {
            color: #181818;
        }


        p,
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="number"],
        input[type="tel"],
        select,
        textarea,
        .breadcrumb-inner ul.page-list li a,
        .rn-address .inner p a,
        .error-inner .description,
        .rbt-single-widget.widget_recent_comments ul li a,
        .rbt-single-widget.widget_recent_entries ul li a,
        .rbt-single-widget.widget_archive ul li a,
        .rbt-single-widget.widget_categories ul li a,
        .rbt-single-widget.widget_meta ul li a,
        .rbt-single-widget.widget_pages ul li a,
        .rbt-single-widget.widget_nav_menu ul li a,
        .rbt-single-widget.widget_recent_entries .inner span,
        .rbt-single-widget.widget_recent_comments ul li span,
        .rbt-single-widget.widget_recent_entries ul li span,
        .rbt-single-widget.widget_archive ul li span,
        .rbt-single-widget.widget_categories ul li span,
        .rbt-single-widget.widget_meta ul li span,
        .rbt-single-widget.widget_pages ul li span,
        .rbt-single-widget.widget_nav_menu ul li span ,
        .post-page-banner .rn-meta-list li a ,
        .post-page-banner .rn-meta-list li,
        .breadcrumb-inner ul.page-list li.rn-breadcrumb-item.active,
        .slider-style-1.bg-transparent .inner .subtitle,
        .slider-style-1.bg-transparent .inner .description,
        .popup-mobile-menu .mainmenu li a,
        .footer-style-default .footer-top .rn-footer-widget .footer-link li a,
        .footer-style-default .footer-top .rn-footer-widget .subtitle,
        .rn-call-to-action.style-8 .content .subtitle,
        .rn-newsletter-default .newsletter-section-title .description,
        .slider-style-5 .inner .subtitle,
        .slider-style-5 .inner .title {
            color: #65676b;
        }

        .header-transparent.sticky .mainmenu-nav .mainmenu > li > a,
        .clltoaction-style-default.style-7 .content .subtitle,
        .copyright-style-one .ft-menu li a,
        .footer-style-default .footer-top .rn-footer-widget .text-big {
            color: #65676b;
            color: black;
        }

        .form-group input,
        .form-group textarea,
        .rn-accordion-style.rn-accordion-02 .card .card-header,
        .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item,
        .breadcarumb-style-1,
        .rn-accordion-style.rn-accordion-03 .card .card-header,
        .rn-accordion-style.rn-accordion-04 .card,
        .rn-pricing .pricing-table-inner,
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="number"],
        input[type="tel"],
        select,
        textarea,
        .rbt-single-widget,
        .rbt-single-widget.widget_recent_comments ul li+li,
        .rbt-single-widget.widget_recent_entries ul li+li,
        .rbt-single-widget.widget_archive ul li+li,
        .rbt-single-widget.widget_categories ul li+li,
        .rbt-single-widget.widget_meta ul li+li,
        .rbt-single-widget.widget_pages ul li+li,
        .rbt-single-widget.widget_nav_menu ul li+li ,
        .tagcloud a, 
        .wp-block-tag-cloud a,
        .section-title-2 .title,
        .popup-mobile-menu .mainmenu li + li ,
        .clltoaction-style-default.style-7 .content-wrapper,
        .copyright-style-one,
        .footer-style-default .newsletter-form .form-group input,
        .newsletter-area .border-top-bottom {
            border-color: #00000024;
        }

        .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover {
            background: #d8d8d8;
            background-color: var(--color-blackest);
        }

        .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover {
            background: #d8d8d8;
            color: var(--color-primary);
        }

        .btn-read-more span::after {
            background: #00000024;
        }

        .breadcrumb-inner ul.page-list li a:hover,
        .rn-address .inner p a:hover,
        .post-page-banner .rn-meta-list li a:hover,
        .header-top-bar .header-left p a:hover,
            .header-top-bar .header-left a:hover {
            color: var(--color-primary);
        }

        .social-default li a:hover,
        .tagcloud a:hover, 
        .wp-block-tag-cloud a:hover,
        .header-top-news .btn-read-more span {
            color: var(--color-white);
            border-color: var(--color-primary);
        }

        .tagcloud a:hover, 
        .wp-block-tag-cloud a:hover {
            border-color: var(--color-primary);
        }

        a.btn-default.text-underline,
        button.btn-default.text-underline {
            border-color: #181818;
            color: #181818;
        }

        .hamberger-button svg,
        .popup-mobile-menu .inner .header-top .close-menu .close-button svg {
            stroke: #181818;
        }

        .single-demo .thumbnail .image-light {
            display: block;
        }

        .single-demo .thumbnail .image-dark {
            display: none;
        }

        .footer-style-default .social-default li a ,
        .copyright-style-one .social-default.color-lessdark li a ,
        .rn-newsletter-default .rn-newsletter .form-group input {
            background: #e0e0e0;
        }
        .rn-newsletter-default .rn-newsletter .form-group input {
            color: #181818;
        }

        .copyright-style-one .ft-menu li + li::after {
            background: #c5c1c1;
        }

        .copyright-style-one .ft-menu li a:hover ,
        .footer-style-default .footer-top .rn-footer-widget .footer-link li a:hover{
            color: var(--color-primary);
        }

        .header-transparent-with-topbar .header-top-bar,
        .header-transparent-with-topbar .header-default {
            background: transparent;
        }

        [class*=col]:nth-child(2) .counter-style-2.color-variation .count-number {
            background-color: #f9f5f5;
        }

        [class*=col]:nth-child(3) .counter-style-2.color-variation .count-number {
            background-color: #eef2f3;
        }

        .service-wrapper > [class*=col]:nth-child(4) .service.service__style--1.icon-circle-style .icon {
            background-color: #2f011d45;
        }


        .slider-style-8 .inner a.btn-default.btn-border, 
        .slider-style-8 .inner button.btn-default.btn-border {
            border-color: #d8d8d8;
            color: #65676b;
        }
        .slider-style-8 .inner a.btn-default.btn-border:hover, 
        .slider-style-8 .inner button.btn-default.btn-border:hover {
            color: #fff;
        }

    }
}

body.light .light-title {
    color: var(--color-blackest) !important;
    .theme-gradient{
        background: -moz-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
        background: -webkit-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
        background: -webkit-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
        background: -o-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
        background: -ms-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
        text-transform: capitalize;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
body.light .litle-page{
    color: var(--color-white);
}
body.light .course-tab p{
    color: #acacac;
}
body.light .border-top-only{
    border-top: 1px solid var(--color-body) !important;
}
body.light .course-tab .inner h4.title {
    color: var(--color-white);
}

body.light .service.service__style--1.icon-circle-style .icon {
    background-color: #059dff0f;
}

body.light .service-wrapper>[class*=col]:nth-child(2) .service.service__style--1.icon-circle-style .icon {
    background-color: #fb53431a;
}

body.light .service-wrapper>[class*=col]:nth-child(3) .service.service__style--1.icon-circle-style .icon {
    background-color: #6549d51f;
}

body.light .timeline-dot::after {
    background: radial-gradient(50% 50% at 50% 50%, #f5f8fa 48.96%, #f5f8fa 100%);
}


body.light .header-top-bar {
    background-color: var(--color-white);
}

body.light .single-timeline .single-content,
body.light .rn-card,
body.light .clltoaction-style-default.style-5 .content-wrapper,
body.light .header-default,
body.light .mainmenu-nav .mainmenu li.has-droupdown .submenu,
body.light .service.gallery-style,
body.light .rn-box-card .inner,
body.light .team-style-default .inner,
body.light .rn-accordion-style .card,
body.light .section-title .subtitle,
body.light .card-box.card-style-1,
body.light .list-icon li .icon,
body.light .counter-style-2 .count-number,
body.light .rn-pricing.style-2 .pricing-table-inner,
body.light .rn-default-tab .rn-tab-content,
body.light .rn-default-tab .tab-button .react-tabs__tab.react-tabs__tab--selected,
body.light .advance-tab-button-1 .tab-button-list .tab-button::before,
body.light .advance-pricing .pricing-left,
body.light .rn-pricing.style-5 .pricing-table-inner,
body.light .about-style-5 .about-wrapper,
body.light .rn-sub-badge,
body.light .about-style-6 .wrapper::after,
body.light .box-grid-layout .rwt-card,
body.light .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper,
body.light .social-default li a,
body.light .clltoaction-style-default.style-4 .content-wrapper,
body.light .rn-address,
body.light .rbt-single-widget .title ,
body.light .frame-image ,
body.light .rn-back-top,
body.light .popup-mobile-menu .inner,
body.light .rn-call-to-action.style-8,
body.light .bg-overlay-solid::before {
    background: #f5f8fa;
    background: #e0e0e0;
}

body.light .rn-header.sticky {
    background: #f5f8fa !important;
}
body.light .service.service__style--1:hover h4{
    color: var(--color-white);
}
body.light .theme-gradient{
    background: var(--color-white);
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.light .subtitle.theme-gradient{
    font-weight: 600;
    background: var(--color-primary);
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.light .section-title .subtitle,
body.light .rn-sub-badge  {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%;
}

body.light .tag{
    background: #e0e0e0;
    color: #000;
}

body.light .service.service__style--1.rbt-border, .service.service__style--3.rbt-border {
    border: 1px solid #e0e0e0;
}

body.light .bg-color-blackest {
    background: #f5f8fa !important;
}

body.light .footer-style-default,
body.light .copyright-style-one {
    background: #f5f8fa;
}

body.light .slider-style-2 .list-icon li .icon {
    background: var(--color-blackest);
}

body.light .rn-pricing .pricing-table-inner::before,
body.light .rn-timeline-single.dark-line .rn-timeline .progress-dot .dot-level,
body.light .rn-timeline-single.dark-line .rn-timeline .progress-line .line-inner,
body.light .card-style-default.testimonial-style-one .inner .thumbnail::after,
body.light .advance-pricing,
body.light .rn-pricing.style-5 .pricing-table-inner .pricing,
body.light .popup-mobile-menu .inner .header-top .close-menu .close-button,
body.light .hamberger-button {
    background: #d8d8d8;
}
body.light .hamberger-button {
    background: #fff;
}

body.light .single-demo {
    background: #e9f8fe;
}
body.light .popup-mobile-menu .mainmenu .resMenuItem .MenuBul
    {
    color: black;
    span{
        color: #666666;
    }
    &:hover{
        color: var(--color-primary);
    }
}

body.light .popup-mobile-menu .inner .header-top .close-menu .close-button:hover{
    background: var(--color-primary);
    svg{
        stroke: #fff;
    }
}
body.light .popup-mobile-menu .socials p,
body.light .popup-mobile-menu .socials a
{
    color: var(--color-blackest);
    transition: all .4s ease;
    &:hover{
        color: var(--color-primary);
    }
}
body.light .social-default.transparent-with-border li a {
    border: 1px solid #e0e0e0;
    transition: all .4s ease;
    &:hover{
        color: var(--color-white);
        border: 1px solid var(--color-primary);
    }
}

body.light .copyright-style-one .copyright-text {
    color: black;
}

body.light .rn-accordion-style .card .card-body,
body.light .brand-style-1 li {
    border-color: #d8d8d8;
}

body.light hr {
    border-top: 1px solid #00000024;
}

body.light .rn-card {
    border-color: #f5f8fa;
}

body.light .rn-pricing.active .pricing-table-inner .pricing-header,
body.light .rn-pricing .pricing-table-inner .pricing-header {
    border-color: #0000000a;
}

body.light .advance-tab-button-1 .tab-button-list .tab-button::after {
    border-left: 20px solid #f5f8fa;
    border-left: 20px solid #e0e0e0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

body.light .counter-style-4.count-box {
    background-color:#e0e0e0;
}
body.light .counter-style-4 {
    .counter-title, .count-number, .count-icon  {
        color: black;
    }
    &:hover .count-icon,
    &:hover .counter-title,
    &:hover .count-number{
        color: var(--color-white);
    }
}
body.light ul li a{
    color: black;
    &:hover{
        color: var(--color-primary);
    }
}
body.light a.btn-default.btn-border,
body.light button.btn-default.btn-border {
    border-color: #00000024;
    color: #181818;
}

body.light a.btn-default.btn-border:hover,
body.light button.btn-default.btn-border:hover {
    border-color: var(--color-secondary);
    color: #fff;
}

body.light .rn-box-card .inner .thumbnail,
body.light .team-style-default .inner .thumbnail {
    border: 6px solid var(--color-white);
}




body.light .filter-button-default li button,
body.light .testimonial-style-two .content .client-info .subtitle,
body.light .single-timeline .single-content .inner .content .date-of-timeline,
body.light .service.gallery-style .content p,
body.light .rn-box-card .inner .social-icon li a,
body.light .team-style-default .inner .social-icon li a,
body.light .mainmenu-nav .mainmenu>li>a,
body.light .mainmenu-nav .mainmenu li.has-droupdown .submenu li a,
body.light .list-icon li,
body.light ul li,
body.light ol li,
body.light .rn-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li,
body.light .rn-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li svg,
body.light .advance-tab-button-1 .tab-button-list .tab-button .title,
body.light .advance-pricing .pricing-left .subtitle,
body.light .header-top-bar .header-left p a,
body.light .header-top-bar .header-left a,
body.light .header-top-bar .header-right .address-content p a,
body.light .header-top-bar .social-default.icon-naked li a,
body.light .progress-info .title,
body.light .progress-info span.progress-number,
body.light .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a,
body.light .clltoaction-style-default.style-4 .content .subtitle {
    color: #65676b;
}
body.light .terms h6::after{
    background-color: #65676b;
}
body.light .notice-board h6, 
body.light .terms h6{
    color: red;
}
body.light .rn-address:hover h4{
    color: var(--color-white);
}
body.light .rn-accordion-style .card .card-body{
    color: var(--color-white);
}

body.light .card-box.card-style-1 .inner .content .description {
    color: #65676b !important;
}

body.light .clltoaction-style-default.style-5 .content .subtitle,
body.light .counter-style-2 .count-number,
body.light .rn-pricing .pricing-table-inner .pricing-header .pricing span.price,
body.light .rn-pricing.active .pricing-table-inner .pricing-header .title,
body.light .rn-pricing.active .pricing-table-inner .pricing-header .pricing span.currency,
body.light .rn-pricing.active .pricing-table-inner .pricing-header .pricing span.subtitle,
body.light .rn-pricing .pricing-table-inner .pricing-header .pricing span.subtitle,
body.light .rn-default-tab .tab-button .rn-tab-button button,
body.light .rn-pricing .pricing-table-inner .pricing-header .pricing span.currency,
body.light .card-style-default.testimonial-style-one .inner .thumbnail::after,
body.light .clltoaction-style-default.style-6 .content .title,
body.light .rn-splite-style .split-inner .title,
body.light .advance-tab-button-1 .tab-button-list .react-tabs__tab--selected .tab-button .title,
body.light .advance-pricing .pricing-left .price-wrapper .price-amount,
body.light .btn-read-more span,
body.light .section-title-2 .title,
body.light .box-grid-layout .rwt-card .inner .content .title,
body.light .clltoaction-style-default.style-5 .content .title,
body.light .clltoaction-style-default.style-4 .content .title ,
body.light .hamberger-button {
    color: #181818;
}
body.light .rn-splite-style .split-inner .sub-title {
    color: #65676b;
}
body.light .rn-accordion-style .card .card-header button{
    color: #fff;
}

body.light .rn-accordion-style .card .card-header button .icon svg{
    fill: var(--color-white) !important;
}

body.light .about-style-6 .maintitle {
    color: #181818 !important;
}

body.light .slider-style-1 .inner .title {
    color: var(--color-white);
}

body.light .testimonial-style-two .content::after {
    color: #f5f8fa;
}

body.light .header-transparent .mainmenu-nav .mainmenu>li>a {
    color: var(--color-body);
}
body.light .header-transparent.dark-hyper .mainmenu-nav .mainmenu>li>a {
    color: #65676b;
}
body.light .header-transparent .mainmenu-nav .mainmenu>li>a:hover{
    color: var(--color-primary);
}
// #65676b

body.light .header-top-bar .header-right .address-content p a:hover {
    color: var(--color-primary);
}

body.light .rn-pricing .pricing-table-inner .pricing-header .pricing span.currency {
    opacity: 0.8;
}

body.light .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover,
body.light .btn-read-more:hover span {
    color: var(--color-primary);
}

body.light .CircularProgressbar .CircularProgressbar-text {
    fill: #181818 !important;
}

body.light .logo-light {
    display: none;
}

body.light .logo-dark {
    display: block;
}

body.light .slider-area.bg-transparent .inner a.btn-default.btn-border, 
body.light .slider-area.bg-transparent .inner button.btn-default.btn-border {
    border-color: #00000024;
    color: #181818;
}

body.light .slider-area.bg-transparent .inner a.btn-default.btn-border:hover, 
body.light .slider-area.bg-transparent .inner button.btn-default.btn-border:hover {
    color: var(--color-white);
}

body.light .rn-gradient-circle {
    opacity: 0.45;
}
body.light .slider-style-6::before{
    background: none;
}

@media (max-width: 768px) {
    body.light .bg-overlay::before {
        background-image: linear-gradient(90deg, #060606 5%, #236692 90%);
    }
}