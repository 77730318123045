.sitemap-title{
    font-size: 2.2rem;
    font-weight: 500;
   
    svg{
        font-size: 2.8rem;
        margin-right: 5px;
    }
}

.underline{
    position: relative;
    &::after{
        position: absolute;
        content: '';
        border-radius: 7px;
        left: 0;
        bottom: -8px;
        height: 4px;
        width: 100px;
        background-color: var(--color-primary);
    }
}