/*------------------------------
    Blog Details 
----------------------------------*/


.post-page-banner {
    .rn-meta-list {
        justify-content: center;
        text-align: left;
        li {
            color: var(--color-body);
            font-size: 20px;
            margin: 0 15px;
            // margin-top: 38px;
            @media #{$md-layout} {
                font-size: 16px;
                margin-top: 8px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
                margin-top: 8px;
            }
            svg {
                margin-right: 10px;
            }
            a {
                color: var(--color-body);
                transition: 0.3s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }

    .category-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;
        margin: -10px;
        padding: 0;
        margin-top: 14px;
        li {
            margin: 10px;
            position: relative;
            &::after {
                position: absolute;
                content: ",";
                right: -9px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}



.blog-details-content {
    .category-meta {
        display: flex;
        padding: 42px 0;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        flex-wrap: wrap;
        span {
            &.text {
                color: var(--color-white);
                font-size: 22px;
                display: inline-block;
                margin: 10px 0;
                margin-top: 0;
            }
        }
    }
    h2{
        @media #{$sm-layout} {
            font-size: 25px;
        }
    }
    
    @media #{$sm-layout} {
        .pr--40{
            padding: 0px 5% !important;
        }
    }
}

.blog-flex{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

    @media #{$sm-layout} {
        flex-direction: column;
    }
    div{
        flex: 1 0 48%;
        ul{
            list-style-type: none;
            padding: 0;
        }
    }

    div:nth-child(2){
        text-align: left;
    }
}

.blog-flex-img{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.number{
    list-style-type:decimal;
}

.blog-img{
    border-radius:20px;
    margin-bottom: 10px;
}

.name{
    width: 100%;;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
}

.blog-relative-nav {
    padding: 40px 0 20px;
    
}

.blog-relative-nav .prev-post,
.blog-relative-nav .next-post {

    position: relative;
    padding: 20px 35px;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    overflow: hidden;

    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: var(--color-primary);
        transition: all .4s ease;
    }
    &:hover::before{
        height: 100%;
    }
}
.blog-relative-nav .next-post{
    @media #{$sm-layout} {
        margin-top: 20px;
    }
}

.blog-relative-nav .prev-post h4,
.blog-relative-nav .next-post h4 {
    font-size: 16px;
    color: #888;
    gap: 8px;
    transition: all .4s ease;
}
.blog-relative-nav .prev-post h4 svg,
.blog-relative-nav .next-post h4 svg{
    font-size: 28px;
    margin: 0px 4px 3px;
}
.blog-relative-nav .prev-post h5,
.blog-relative-nav .next-post h5 {
    font-size: 18px;
    color: #333;
    margin-top: 5px;
    transition: all .4s ease;
}
.blog-relative-nav .prev-post:hover h4,
.blog-relative-nav .next-post:hover h4,
.blog-relative-nav .prev-post:hover h5,
.blog-relative-nav .next-post:hover h5 {
    position: relative;
    z-index: 4;
    color: var(--color-white);
}

.text-right {
    text-align: right;
}

.page-title{
    h1{
        @media #{$sm-layout} {
            font-size: 30px;
        }
    } 
}